<template>
  <b-card body-class="p-0" header-class="border-0">
    <template v-slot:header>
      <b-row align-v="center">
        <b-col sm="12" lg="2">
          <h3 class="mb-0 title">
            Referrals
          </h3>
        </b-col>
        <b-col sm="12" lg="4">
          <b-form-group
            id="search-wrapper"
            label-for="input-1"
            v-bind:class="{ active: isActive, focused: isFocused }"
          >
            <b-form-input
              id="filter-input"
              ref="filterinput"
              type="text"
              placeholder="Search Referrals"
              @keyup="filterTableBySearch"
              @blur.native="removeCancelSearchIcon"
              @focus="focused"
            ></b-form-input>
            <span class="clear-search" @click="cancelFilterBySearch()">
              <i class="fas fa-times"></i>
            </span>
          </b-form-group>
        </b-col>
        <b-col sm="12" lg="2">
                        <el-date-picker v-model="fromDate"
                          type="datetime" @change="filterDates($event)"
                          placeholder="from" class="input-center">
                        </el-date-picker>
            </b-col>
            <b-col sm="12" lg="2">
                <el-date-picker v-model="toDate"
                  type="datetime" @change="filterDates($event)"
                  placeholder="until">
                </el-date-picker>
            </b-col>
        <b-col class="text-right add-user-ds" md="4" lg="4">

        </b-col>
      </b-row>
    </template>
    <spinner v-if="isLoading"></spinner>
    <!-- Filter -->
    <el-table
      class="table-responsive table leads"
      :data="displayData"
      header-row-class-name="thead-light"
      v-if="!isLoading"
      @sort-change="sortChange"
    >
      <el-table-column label="Fullname" min-width="120px" prop="Name" sortable>
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.name }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Phone number"
        min-width="160px"
        prop="phoneNumber"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.phoneNumber }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Distributor"
        min-width="160px"
        prop="supplierName"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.supplierName }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Branch"
        min-width="160px"
        prop="Area"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.Area }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Date Created"
        min-width="160px"
        prop="dateCreated"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.dateCreated }}</div>
        </template>
      </el-table-column>

      <el-table-column
        label="Status"
        min-width="160px"
        prop="status"
        sortable
      >
        <template v-slot="{ row }">
          <div class="font-weight-600">{{ row.status }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Actions" min-width="60px" v-if="!this.isSupplier">
        <template v-slot="{ row }">
          <div class="row-buttons">
            <button
            v-if="row.status != 'CONVERTED'"
              class="btn btn-sm btn-primary"
              :data-id="row.Id"
              @click="showEditStatusModal(row.Id)"
            >
              <span class="button-text">Edit</span>
              <span class="button-icon"><i class="fas fa-pen"></i></span>
            </button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="currentPage"
      @current-change="handleCurrentChange"
      :total="rows"
      :page-size="pageSize"
      align="right"
    >
    </el-pagination>
    <modal
      :show="isModalVisible"
      @close="closeModal"
      :hide-footer="true"
      class="user-modal"
    >
      <b-form
        class="form"
        @submit.prevent="onEditStatusSubmit"
        v-if="!loading && success == null && editState == false"
      >

        <b-form-group
          id="input-group-1"
          label="Status:"
          label-for="input-1"
        >
          <b-form-select
            v-model="form.status"
            :options="statuses"
          ></b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="ID Number"
          label-for="input-3"
        >
          <b-form-input
            v-model="form.idNumber"
            disabled
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Address"
          label-for="input-2"
        >
          <b-form-textarea
            id="input-2"
            v-model="form.address"
            disabled
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          id="input-group-2"
          label="Notes:"
          label-for="input-2"
        >
          <b-form-textarea
            id="input-2"
            v-model="form.notes"
            type="text"
            placeholder="Enter Notes"
            required
          ></b-form-textarea>
        </b-form-group>
        <b-button type="submit" variant="primary" v-if="form.status != 'CONVERTED'">
          Update status
        </b-button>
        <b-button @click="closeModal" type="button" variant="primary cancel"
          >Cancel</b-button
        >
      </b-form>
      <div class="success-message" v-if="loading && !editState">
        <spinner v-if="loading"></spinner>
      </div>
      <div class="success-message" v-if="editState">
        <div class="icon-wrapper">
          <i class="fas fa-check"></i>
        </div>
        <p>Updated successfully!</p>
        <b-button @click="closeUpdateModal" type="button" variant="primary"
          >Close</b-button
        >
      </div>
    </modal>  
  </b-card>
  
</template>
<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  DatePicker, 
  TimePicker,
  Dropdown,
  Pagination
} from "element-ui";
import modal from "../../components/Modal.vue";
import config from "../../config";
import { leadService } from "../../services/lead.service";
import Spinner from "../../components/Spinner.vue";
import { sha256 } from "js-sha256";
import { DateTime } from 'luxon';

export default {
  name: "leads-table",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [DatePicker.name]: DatePicker,
    [TimePicker.name]: TimePicker,
    [Pagination.name]: Pagination,
    modal,
    Spinner
  },
  data() {
    return {
      selected: 0,
      isActive: false,
      isFocused: false,
      currentPage: 1,
      pageSize: 10,
      selectedName: "",
      selectedId: "",
      isModalVisible: false,
      editState: false,
      fromDate: DateTime.now().startOf('week').toJSDate(),
      toDate: DateTime.now().endOf('day').toJSDate(),
      statuses: ['PENDING', 'REJECTED'],
      tableData: [
        {
          Id: "0",
          supplierName: "Loading...",
          Name: "Loading...",
          Branch: "Loading...",
          DateCreated: "2021-03-13T09:23:10.000Z",
          totalTransactions: 0,
          devices: 0
        }
      ],
      masterData: [],
      
      form: {
        status: "PENDING",
        nodes: "",
        Id: 0
      },
    };
  },
  computed: {
    loading() {
      return this.$store.getters["leads/loading"];
    },
    success() {
      return this.$store.getters["leads/success"];
    },
    error() {
      return this.$store.getters["leads/error"];
    },
    formattedFromDate: {
        get: function () {
          return this.formatDateISO(this.fromDate);
        },
        set: function (newValue) {
          this.fromDate = newValue;
        }
      },
      formattedToDate: {
        get: function () {
          return this.formatDateISO(this.toDate);
        },
        set: function (newValue) {
          this.toDate = newValue;
        }
      },
      
      
    rows() {
      return this.tableData.length;
    },
    displayData() {
      if (!this.tableData || this.tableData.length === 0) return [];
      return this.tableData.slice(
        this.pageSize * this.currentPage - this.pageSize,
        this.pageSize * this.currentPage
      );
    },
    isLoading() {
      return false;
    },
    isSupplier() {
      return this.$store.getters["accounts/user"].supplierIds != ""
        ? true
        : false;
    },
    loggedinSupplierId() {
      return this.$store.getters["accounts/user"].supplierIds;
    },
  },
  methods: {
    async onStatusChange() {
      // user data to edit
      const lead = {
        Id: this.selectedId,
        status: this.status ? 'PENDING' : 'REJECTED'
      };
      
      // Dispatch action for adding USER
      const result = await this.$store.dispatch("leads/updateStatus", lead);
      // Check and handle result
      if (result && result.data == "OK") {
        //TODO: UPDATE UI display
      }
    },
    sortChange($event) {
      // Get sort change data
      const { prop, order } = $event;
      console.log($event);
      // Check for sort order and table data accordingly
      if (order == "ascending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] > b[prop] ? 1 : -1
        );
      }

      if (order == "descending") {
        this.tableData = this.tableData.sort((a, b) =>
          a[prop] < b[prop] ? 1 : -1
        );
      }
      console.log("Table data:", this.tableData);
    },
    async onEditStatusSubmit() {
      // user data to edit
      const lead = {
        Id: this.selectedId,
        notes: this.form.notes,
        status: this.form.status,
        address: this.form.address
      };
      // Dispatch action for adding USER
      const result = await this.$store.dispatch("leads/updateLeadStatus", lead);
      // Check and handle result
      if (result && result.data.status == "success") {
        this.editState = true;
        setTimeout(async () => {
          // close modal
          this.closeModal();
          this.editState = false;
          //this.editUserModal = false;
          // Reset form fields
          this.onReset();
        }, 500);
      }
    },
    showEditStatusModal(leadId) {
      // Set selected id for update state
      this.selectedId = leadId;
      this.isModalVisible = true;
      // get user data from the store and populate form fields
      const lead = this.$store.state.leads.leads.find(
        lead => lead.Id.toString() === leadId.toString()
      );
      // Populate form field values with
      if (lead) {
        // Generate supplier data information
        this.form.status = lead.status;
        this.form.notes = lead.notes;
        this.form.address = lead.Address;
        this.form.idNumber = lead.idNumber;
      }
    },
    showModal() {
      // Ensure form is clear
      this.onReset();
      // Change updatingUser state to true
      this.isModalVisible = true;
    },
    closeModal() {
      // Close modal

      this.isModalVisible = false;
      // Reset form fields
      //this.onReset();
      setTimeout(() => {
        this.onReset();
      }, 500);
    },
    closeModalHandler() {
      this.isModalVisible = false;
      this.onReset();
      setTimeout(() => {
        this.$store.dispatch("users/complete");
      }, 500);
    },
    onReset() {
      // Resets form values
      this.form.notes = "";
      this.form.status = "";
      this.form.idNumber = "";
      this.form.address = "";
    },
      formatDate(cellValue) {
        var date = new Date(cellValue);
        return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      },
      formatDateISO(inputDate) {
      var date = new Date(inputDate);
      return (
        date.getFullYear() +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + date.getDate()).slice(-2) +
        " " +
        ("0" + date.getHours()).slice(-2) +
        ":" +
        ("0" + date.getMinutes()).slice(-2)
      );
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
      filterDates(event) {
        console.log('SEARCHING');
        // Get supplier id 
        const supplierIds = this.$store.getters["accounts/user"].supplierIds;

        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { 'Authorization': this.$store.getters['accounts/token'] }
        });

        http.post('/lead/search/', {
            fromDate: this.formattedFromDate,
            toDate: this.formattedToDate,
            supplierIds: supplierIds
        }).then(response => {
          if (response.data.length > 0) {
            /* Table data */
            this.tableData = response.data;
            this.masterData = response.data;
            this.$store.dispatch("leads/updateLeads", response.data);
            console.log('Table data (loaded):', this.tableData);
            // Call method that will filter table data
            this.displayFilteredTableData();
            this.handleCurrentChange(1);
          } else {
            this.tableData = [];
            this.handleCurrentChange(1);
          }
          
        }).catch((err) => {
          this.tableData = [];
          this.masterData = []
          this.handleCurrentChange(1);
        });

      },
      displayFilteredTableData() {
        console.log('FILTER', this.filter);
        if (this.filter && this.filter != ''){
          this.tableData = this.masterData.filter(s => s.supplierName.includes(this.filter))
        } else {
          this.tableData = this.masterData;
        }
      },
    filterTableBySearch($event) {
      // Get typed in value in input
      const query = $event.target.value.trimStart().toLowerCase();
      // Get pressed key code
      const pressKeyCode = $event.keyCode;
      // Store length of query
      const queryLength = query != "" ? query.length : 0;

      if (queryLength > 0) {
        // Show cancel icon
        this.isActive = true;
        // When the backspace key is pressed
        if (pressKeyCode == 8) {
          // Get value in input once again
          const value = $event.target.value.trimStart().toLowerCase();
          // Filter data
          this.filterTableData(value);
        }
        // Filter clients data
        this.filterTableData(query);
      } else {
        // Show cancel icon
        this.isActive = false;
        // Reset table data when there is no value in input
        this.resetTableData();
      }
    },
    filterTableData(query = "") {
      // Get all clients from store
      const data = this.$store.getters["leads/leads"];
      // Filter clients by search query
      const filterdClients = data.filter(
        c =>
          c.name.toLowerCase()
            .startsWith(query) ||
          c.phoneNumber.toLowerCase()
            .startsWith(query) ||
          c.Area
            .toLowerCase()
            .startsWith(query) ||
          c.status
            .toLowerCase()
            .startsWith(query) ||
          c.idNumber.toLowerCase().includes(query)
      );
      // Store the filtered data as the new tatble data values
      this.tableData = filterdClients;
    },
    resetTableData() {
      // When query length is zero input is in empty state, we get client from store
      const data = this.$store.getters["leads/leads"];
      // Store clients from store and update table data
      this.tableData = data;
    },
    cancelFilterBySearch() {
      // Get the search input and set it to null/empty
      const input = (this.$refs.filterinput.$el.value = "");
      // Remove focused class
      this.isFocused = false;
      // Remove active class
      this.isActive = false;
      // Reset table data
      this.resetTableData();
    },
    removeCancelSearchIcon($event) {
      // Get typed in value in input
      const queryLength = $event.target.value.trimStart().length;
      // Remove cancel icons based on length value length
      if (queryLength == 0) {
        // Reverse is active
        this.isActive = false;
        // Reverse is focused
        this.isFocused = false;
      }
    },
    focused() {
      // Set focused class on search input wrapper
      this.isFocused = true;
    },
    
  },
  mounted() {
    var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });
    if (this.isSupplier) {
      http.post("/lead/search", {
            keywords: '',
            fromDate: this.formattedFromDate,
            toDate: this.formattedToDate,
            supplierIds: this.loggedinSupplierId || ''
        }).then(response => {
        this.tableData = response.data;
        this.masterData = response.data;
        this.$store.dispatch("leads/updateLeads", response.data);
        this.handleCurrentChange(1);
      });
    } else {
      http.post("/lead/search", {
            keywords: '',
            fromDate: this.formattedFromDate,
            toDate: this.formattedToDate,
            supplierIds: ''
        }).then(response => {
        this.tableData = response.data;
        this.masterData = response.data;
        this.$store.dispatch("leads/updateLeads", response.data);
        this.handleCurrentChange(1);
      });
    }
  }
};
</script>
<style>
.buttons {
  text-align: center;
  margin-bottom: 10px;
}

.user-modal {
  display: none;
}

.cancel {
  color: #212529;
  background-color: #d2e3ee;
  border-color: #d2e3ee;
}

.cancel:hover {
  background-color: #d2e3ee;
  border-color: #d2e3ee;
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  background: #f7941d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 5px;
}

.icon-wrapper i {
  font-size: 30px;
  color: #fff;
}

.success-message {
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}

.success-message p {
  color: #f7941d;
  margin-top: 15px;
}

.report-times {
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  margin-top: 5px;
}

.report-times .title {
  color: #f7941d;
  font-size: 12px;
}

.devices {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  list-style: none;
  padding: 0 5px;
  border-bottom: 1px solid #ebeef5;
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  margin-bottom: 35px;
}

.devices .device {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ebeef5;
  padding: 10px 0;
}

.devices .device button {
  color: #fff;
  background-color: #f1f1f1;
  border-color: #f7941d;
  outline: 0;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 4px;
}

.devices .device button i {
  color: #f7941d;
}

.error .message-icon {
  background: #f5f5f5;
}

.error .message-icon i {
  color: #f7941d;
}

.message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 50px;
}

.message-icon {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.message.delete {
  margin-top: 10px;
  margin-bottom: 10px;
}

.message.delete .message-icon {
  background: #f5f5f5;
}

.message.delete .message-icon i {
  color: #f7941d;
  margin-top: -4px;
}

.message-icon i {
  font-size: 30px;
}

.message span {
  color: #525f7f;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 20px;
}

.form-control:focus,
.form-control:active {
  background: #fff !important;
}

.label {
  font-size: 12px;
}

.periods {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
}

.add-user .periods {
  margin-top: -10px;
  margin-bottom: 10px;
}

.add-user span {
  font-size: 12px;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 600;
  display: flex;
  margin-right: 5px;
  margin-bottom: 7px;
}

.add-user span a {
  text-decoration: underline;
  font-size: 12px;
  display: inline-block;
  margin-right: 5px;
}

.add-user span .remove {
  margin-top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 4px;
  color: #f7941d;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #f1f1f1;
  cursor: pointer;
}

.add-user span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
  font-size: 11px;
  margin-left: -3px;
}

.add-user .time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 4px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
  font-size: 10px;
}

span.time {
  background: #f7941d;
  border-radius: 25px;
  font-size: 12px;
  color: #fff !important;
  position: relative;
  max-width: 75px;
  display: flex;
  align-items: center;
  padding: 5px;
  font-weight: 600;
  display: flex;
  min-height: 30px;
  min-width: 75px;
  margin-right: 10px;

  margin-bottom: 7px;
}

span.time .text {
  padding-left: 5px;
  line-height: 0 !important;
  display: inline-block;
  margin-bottom: -1px;
  padding-left: 5px;
}

.time .remove {
  display: flex;
  border-radius: 50%;
  background: #fff;
  width: 18px;
  height: 18px;
  position: absolute;
  right: 5px;
  cursor: pointer;
  color: #f7941d;
  justify-content: center;
  align-items: center;
}

.time-error {
  color: #f7941d;
  font-size: 12px;
}

.form-control[readonly] {
  background-color: #fff !important;
}

#search-wrapper {
  position: relative;
  margin: 0;
}

#search-wrapper.focused input {
  border-color: #f7941d;
}

#search-wrapper.active .clear-search {
  display: flex;
}

#search-wrapper .clear-search {
  padding-top: 2px;
  position: absolute;
  top: 50%;
  right: 15px;
  display: flex;
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: none;
}
#search-wrapper .clear-search i {
  font-size: 13px;
  line-height: none;
  padding: 0;
  margin: 0;
  color: #525f7f;
}
</style>
