//import config from '../../config';
import { sha256 } from 'js-sha256';
import config from '../config';

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`api/lead`, requestOptions).then(handleResponse);
}

function getAllBySupplier(supplierIds) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({supplierIds:supplierIds })
  };

  return fetch(`api/lead/:supplierId`, requestOptions).then(handleResponse);
}

function updateStatus(id, status, notes) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({Id:id, status: status, notes: notes })
  };

  return fetch(`api/lead`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export const leadService = {
  getAll,
  getAllBySupplier,
  updateStatus
};
